import { defineStore } from 'pinia'
import { getChannelsREQFn, getSimpleChannelsREQFn, delChannelREQFn, getChannelsDetailsFn, editChannelREQFn, searchChannelFn } from '@/api/message/channels'

export const useChannelInfo = defineStore('channelInfo', {
  state: () => ({
    channels: [],
    showAddEditDlg: false,
    showQrCodeDlg: false,
    addEditDlgInfo: {},
    getChannelsListProgressLoading: false,
    channelsLists: [], // 詳細渠道列表
    channelSimpleList: [], // 渠道簡單列表(含已刪除)
    currentChannel: 'web',
    thirdPartyFriendUrl: '',
    channelMap: new Map()
  }),
  getters: {

  },
  actions: {
    async getChannelSimpleList (t) { // 取得渠道簡單列表
      const params = {
        type: 'all'
      }
      const res = await getSimpleChannelsREQFn(params)
      const data = res.data.value
      if (data.code === 200) {
        const channelData = data.data
        channelData.forEach((item) => {
          if (item.isDeleted != null && item.isDeleted !== 0) {
            item.channelName = item.channelName + '(' + t('common.deleted') + ')'
          }

          this.channelMap.set(item.id, item)
        })

        this.channelSimpleList = channelData
      }
    },
    async getChannelsList (params) { // 取得渠道列表
      const res = await getChannelsREQFn(params)
      const data = res.data.value
      if (data.code === 200) {
        this.channelsLists.push(data.data)
        this.getChannelsListProgressLoading = false
      }
    },
    async refreshChannelsList (params) { // 更新渠道列表
      const paramsValue = {
        channelType: this.currentChannel,
        pageNum: params.pageNum,
        pageSize: params.pageSize
      }

      const res = await getChannelsREQFn(paramsValue)
      const data = res.data.value
      if (data.code === 200) {
        if (this.currentChannel === 'web') {
          this.channelsLists[0] = data.data
        }else if (this.currentChannel === 'tg') {
          this.channelsLists[1] = data.data
        }else if (this.currentChannel === 'whatsapp') {
          this.channelsLists[2] = data.data
        }else if (this.currentChannel === 'messenger') {
          this.channelsLists[3] = data.data
        }
      }
    },
    async delChannel (channelId, params) { // 刪除渠道
      const paramsValue = {
        pageNum: params.pageNum,
        pageSize: params.pageSize
      }
      const delParams = {
        channelId
      }
      const res = await delChannelREQFn(delParams)
      const data = res.data.value
      if (data.code === 200) {
        this.refreshChannelsList(paramsValue)
        ElMessage.success({ message: '刪除成功' })
      }
    },
    async getChannelsDetails (url) { // 獲取渠道細節
      if (this.channels.length > 0) { return this.channels }
      const params = {
        url
      }
      const res = await getChannelsDetailsFn(params)
      const data = res.data.value
      if (data.code === 200) {
        this.channels = data.data
      }
      return this.channels
    },
    async editChannels (channelId, formData, channelUrl) { // 修改渠道
      const params = {
        channelId
      }
      const res = await editChannelREQFn(params, formData)
      const data = res.data.value
      if (data.code === 200) {
        ElMessage.success({ message: '保存成功' })
        this.getChannelsDetails(channelUrl)
      }
    },
    async searchChannel (params) { // 搜尋渠道
      const res = await searchChannelFn(params)
      const data = res.data.value
      if (data.code === 200) {
        if (this.currentChannel === 'web') {
          this.channelsLists[0] = data.data
        }else if (this.currentChannel === 'tg') {
          this.channelsLists[1] = data.data
        }else if (this.currentChannel === 'whatsapp') {
          this.channelsLists[2] = data.data
        }else if (this.currentChannel === 'messenger') {
          this.channelsLists[3] = data.data
        }
      }
    }
  }
})
